var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cloudStorageList"},[_c('ul',{staticClass:"m-menu__nav m-0 p-0"},[_c('li',{staticClass:"m-menu__item m-menu__item--submenu m-menu__item--rel px-1 m-0"},[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.$t('cloudStorageList.currentPath'))+":")]),_c('a',{staticClass:"m-menu__link m-menu__toggle",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.getPaths('')}}},[_c('span',{staticClass:"m-menu__link-text"},[_vm._v(_vm._s(_vm.removeParents('/')))])])]),_vm._l((_vm.parentList),function(parent,index){return [_c('li',{key:'li1' + index,staticClass:"m-menu__item m-menu__item--submenu m-menu__item--rel arrow"},[_vm._v(" / ")]),_c('li',{key:'li2' + index,staticClass:"m-menu__item m-menu__item--submenu m-menu__item--rel px-1 m-0"},[_c('a',{staticClass:"m-menu__link m-menu__toggle",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.getPaths(parent)}}},[_c('span',{staticClass:"m-menu__link-text"},[_vm._v(_vm._s(_vm.removeParents(parent).replace("/","")))])])])]})],2),_c('div',{staticClass:"clearfix"}),_c('br'),(_vm.loading)?_c('LoadingPlaceholder'):[(_vm.directoriesAvailable)?_c('Grid',{ref:_vm.kgm_ref,style:({height: 'auto'}),attrs:{"data-items":_vm.kgm_computedGridItems(_vm.directories),"columns":_vm.kgm_responsiveColumns('directoryColumns'),"filterable":true,"filter":_vm.kgm_filter,"pageable":_vm.kgm_pagable,"page-size":_vm.kgm_take,"skip":_vm.kgm_skip,"take":_vm.kgm_take,"total":_vm.kgm_allGridItems(_vm.directories),"sortable":{
        allowUnsort: _vm.kgm_allowUnsort,
        mode: _vm.kgm_sortMode
      },"sort":_vm.kgm_sort,"selected-field":"selected"},on:{"rowclick":_vm.kgm_gridOnRowClick,"filterchange":_vm.kgm_gridFilterChange,"pagechange":_vm.kgm_gridPageChange,"sortchange":_vm.kgm_gridSortChange},scopedSlots:_vm._u([{key:"directoryLink",fn:function(ref){
      var props = ref.props;
return [_c('td',{class:props.className},[_c('a',{staticClass:"alt-primary-color",attrs:{"href":"javascript:;"},on:{"click":function($event){$event.preventDefault();return _vm.getPaths(props.dataItem.path)}}},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"folder"}}),_c('span',[_vm._v(_vm._s(_vm.kgm_getNestedValue(props.field, props.dataItem)))])],1)])]}}],null,false,4131575837)}):_vm._e(),(_vm.filesAvailable)?_c('Grid',{ref:_vm.kgm_ref,style:({height: 'auto'}),attrs:{"data-items":_vm.kgm_computedGridItems(_vm.files),"columns":_vm.kgm_responsiveColumns('filesColumns'),"filterable":false,"filter":_vm.kgm_filter_files,"pageable":_vm.kgm_pagable,"page-size":_vm.kgm_take,"skip":_vm.kgm_skip,"take":_vm.kgm_take,"total":_vm.kgm_allGridItems(_vm.files),"sortable":{
        allowUnsort: _vm.kgm_allowUnsort,
        mode: _vm.kgm_sortMode
      },"sort":_vm.kgm_sort,"selected-field":"selected"},on:{"rowclick":_vm.kgm_gridOnRowClick,"filterchange":_vm.kgm_gridFilterChange,"pagechange":_vm.kgm_gridPageChange,"sortchange":_vm.kgm_gridSortChange},scopedSlots:_vm._u([{key:"filterSlotTemplate",fn:function(ref){
      var props = ref.props;
      var methods = ref.methods;
return _c('div',{staticClass:"k-filtercell"},[_c('div',{staticClass:"k-filtercell-wrapper"},[_c('input',{ref:"sizeInput",staticClass:"k-textbox",attrs:{"type":"number","placeholder":"bytes"},on:{"input":function($event){return _vm.onSizeInputChange($event, methods, props)}}}),_c('div',{staticClass:"k-filtercell-operator"},[_c('select',{key:("sizeOperator-" + (props.value)),ref:"sizeOperator",staticClass:"k-textbox alt-pointer",staticStyle:{"width":"30px"},domProps:{"value":props.value},on:{"click":function (ev) { methods.change({operator: _vm.$refs.sizeOperator.value, field: props.field, value: Number(_vm.$refs.sizeInput.value), syntheticEvent: ev}); }}},[_c('option',{attrs:{"value":"eq"}},[_vm._v(" Is equal to ")]),_c('option',{attrs:{"value":"neq"}},[_vm._v(" Is not equal to ")]),_c('option',{attrs:{"value":"isnull"}},[_vm._v(" Is null ")]),_c('option',{attrs:{"value":"isnotnull"}},[_vm._v(" Is not null ")]),_c('option',{attrs:{"value":"gte","selected":"selected"}},[_vm._v(" Is greater than or equal to ")]),_c('option',{attrs:{"value":"lte"}},[_vm._v(" Is less than or equal to ")]),_c('option',{attrs:{"value":"lt"}},[_vm._v(" Is less than ")])]),_c('button',{staticClass:"k-button k-button-icon k-clear-button-visible",on:{"click":function (ev) { methods.change({operator: '', field: '', value: '', syntheticEvent: ev}); }}},[_c('span',{staticClass:"k-icon k-i-filter-clear"})])])])])}},{key:"fileLink",fn:function(ref){
      var props = ref.props;
return [_c('td',{class:props.className},[_c('a',{staticClass:"alt-primary-color",attrs:{"href":"javascript:;"},on:{"click":function($event){$event.preventDefault();return _vm.download(props.dataItem.path)}}},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"file"}}),_c('span',[_vm._v(_vm._s(_vm.kgm_getNestedValue(props.field, props.dataItem)))])],1)])]}},{key:"fileSize",fn:function(ref){
      var props = ref.props;
return [_c('td',{class:props.className},[_c('span',[_vm._v(_vm._s(_vm.numberConversionAdapter_bytes(_vm.kgm_getNestedValue(props.field, props.dataItem)).bytes)+" "+_vm._s(_vm.numberConversionAdapter_bytes(_vm.kgm_getNestedValue(props.field, props.dataItem)).unit))])])]}},{key:"fileDate",fn:function(ref){
      var props = ref.props;
return [_c('td',{class:props.className},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.dateTime_fromNow(_vm.kgm_getNestedValue(props.field, props.dataItem))),expression:"dateTime_fromNow(kgm_getNestedValue(props.field, props.dataItem))"}]},[_vm._v(_vm._s(_vm.dateTime_dateTime(_vm.kgm_getNestedValue(props.field, props.dataItem))))])])]}}],null,false,4173387638)}):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }