<template>
  <!-- 
    Lists files and folders that are saved on the Amazon Cloud Storage Server.
   -->
  <div class="cloudStorageList">
    <ul class="m-menu__nav m-0 p-0">
      <li class="m-menu__item m-menu__item--submenu m-menu__item--rel px-1 m-0">
        <span class="mr-2">{{ $t('cloudStorageList.currentPath') }}:</span>
        <a
          class="m-menu__link m-menu__toggle"
          href="#"
          @click.prevent="getPaths('')"
        >
          <span class="m-menu__link-text">{{ removeParents('/') }}</span>
        </a>
      </li>
      <template v-for="(parent, index) in parentList">
        <li
          :key="'li1' + index"
          class="m-menu__item m-menu__item--submenu m-menu__item--rel arrow"
        >
          /
        </li>
        <li
          :key="'li2' + index"
          class="m-menu__item m-menu__item--submenu m-menu__item--rel px-1 m-0"
        >
          <a
            class="m-menu__link m-menu__toggle"
            href="#"
            @click.prevent="getPaths(parent)"
          >
            <span class="m-menu__link-text">{{ removeParents(parent).replace("/","") }}</span>
          </a>
        </li>
      </template>
    </ul>

    <div class="clearfix" />
    <br>

    <LoadingPlaceholder v-if="loading" />

    <template v-else>
      <Grid
        v-if="directoriesAvailable"
        :ref="kgm_ref"
        :style="{height: 'auto'}"
        :data-items="kgm_computedGridItems(directories)"
        :columns="kgm_responsiveColumns('directoryColumns')"
        :filterable="true"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(directories)"
        :sortable="{
          allowUnsort: kgm_allowUnsort,
          mode: kgm_sortMode
        }"
        :sort="kgm_sort"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <template
          slot="directoryLink"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <a
              href="javascript:;"
              class="alt-primary-color"
              @click.prevent="getPaths(props.dataItem.path)"
            >
              <font-awesome-icon
                class="mr-2"
                icon="folder"
              />
              <span>{{ kgm_getNestedValue(props.field, props.dataItem) }}</span>
            </a>
          </td>
        </template>
      </Grid>
      <Grid
        v-if="filesAvailable"
        :ref="kgm_ref"
        :style="{height: 'auto'}"
        :data-items="kgm_computedGridItems(files)"
        :columns="kgm_responsiveColumns('filesColumns')"
        :filterable="false"
        :filter="kgm_filter_files"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(files)"
        :sortable="{
          allowUnsort: kgm_allowUnsort,
          mode: kgm_sortMode
        }"
        :sort="kgm_sort"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <div
          slot="filterSlotTemplate"
          slot-scope="{props, methods}"
          class="k-filtercell"
        >
          <div class="k-filtercell-wrapper">
            <input
              ref="sizeInput"
              type="number"
              placeholder="bytes"
              class="k-textbox"
              @input="onSizeInputChange($event, methods, props)"
            >
            <div class="k-filtercell-operator">
              <select
                ref="sizeOperator"
                :key="`sizeOperator-${props.value}`"
                :value="props.value"
                class="k-textbox alt-pointer"
                style="width: 30px;"
                @click="(ev) => { methods.change({operator: $refs.sizeOperator.value, field: props.field, value: Number($refs.sizeInput.value), syntheticEvent: ev}); }"
              >
                <option value="eq">
                  Is equal to
                </option>
                <option value="neq">
                  Is not equal to
                </option>
                <option value="isnull">
                  Is null
                </option>
                <option value="isnotnull">
                  Is not null
                </option>
                <option
                  value="gte"
                  selected="selected"
                >
                  Is greater than or equal to
                </option>
                <option value="lte">
                  Is less than or equal to
                </option>
                <option value="lt">
                  Is less than
                </option>
              </select>
              <button
                class="k-button k-button-icon k-clear-button-visible"
                @click="(ev) => { methods.change({operator: '', field: '', value: '', syntheticEvent: ev}); }"
              >
                <span class="k-icon k-i-filter-clear" />
              </button>
            </div>
          </div>
        </div>
        <template
          slot="fileLink"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <a
              href="javascript:;"
              class="alt-primary-color"
              @click.prevent="download(props.dataItem.path)"
            >
              <font-awesome-icon
                class="mr-2"
                icon="file"
              />
              <span>{{ kgm_getNestedValue(props.field, props.dataItem) }}</span>
            </a>
          </td>
        </template>
        <template
          slot="fileSize"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <span>{{ numberConversionAdapter_bytes(kgm_getNestedValue(props.field, props.dataItem)).bytes }} {{ numberConversionAdapter_bytes(kgm_getNestedValue(props.field, props.dataItem)).unit }}</span>
          </td>
        </template>
        <template
          slot="fileDate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <span v-tooltip="dateTime_fromNow(kgm_getNestedValue(props.field, props.dataItem))">{{ dateTime_dateTime(kgm_getNestedValue(props.field, props.dataItem)) }}</span>
          </td>
        </template>
      </Grid>
    </template>
  </div>
</template>

<script>
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';
import numberConversionAdapter from '@/adapter/numberConversionAdapter.js';
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';

export default {
  name: "CloudStorageList",
  mixins: [
    dateTimeMixin,
    numberConversionAdapter,
    kendoGridMixin
  ],
  props: {
    path: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      directories: [],
      files: [],
      parentList: [],
      file: null,
      kgm_sort: [
        {
          "field": "name",
          "dir": "asc"
        }
      ],
      kgm_filter_files: {
        logic: "and",
        filters: [
          {
            field: "name",
            operator: "contains",
            value: ""
          },
          {
            field: "size",
            operator: "gte",
            value: ""
          }
        ]
      },
      kgm_filter: {
        logic: "and",
        filters: [
          {
            field: "name",
            operator: "contains",
            value: ""
          }
        ]
      },
      filesColumns: [
        {
          field: 'name',
          filterable: true,
          filter: 'text',
          title: 'Name',
          cell: 'fileLink'
        },
        {
          field: 'size',
          filterable: true,
          filter: 'numeric',
          title: 'Size',
          cell: 'fileSize',
          width: '150px',
          hideOn: ["mdDown"],
          filterCell: "filterSlotTemplate"
        },
        {
          field: 'lastChangeDate',
          filterable: true,
          filter: 'date',
          title: 'Date',
          cell: 'fileDate',
          width: '250px',
          hideOn: ["lgDown"]
        }
      ],
      directoryColumns: [
        {
          field: 'name',
          filterable: true,
          filter: 'text',
          title: 'Name',
          cell: 'directoryLink'
        }
      ]
    }
  },
  computed: {
    directoriesAvailable: function () {
      if (this.directories && this.directories.length > 0) {
        return true;
      }
      return false;
    },
    filesAvailable: function () {
      if (this.files && this.files.length > 0) {
        return true;
      }
      return false;
    }
  },
  beforeRouteUpdate (to, from, next) {
    if (this.directories && this.directories[this.directories.length - 1] && this.directories[this.directories.length - 1].path) {
      this.getPaths(this.directories[this.directories.length - 1].path)
    } else {
      this.getPaths('');
    }
    next();
  },
  created () {
    this.$eventBus.$on('cloudStorageList_getPaths', this.getPaths);
    this.$eventBus.$on('cloudStorageList_download', this.download);
  },
  mounted () {
    this.checkPath();
  },
  beforeDestroy () {
    this.$eventBus.$off('cloudStorageList_getPaths', this.getPaths);
    this.$eventBus.$off('cloudStorageList_download', this.download);
  },
  methods: {
    onSizeInputChange (event, methods, props) {
      methods.change({
        operator: this.$refs.sizeOperator.value,
        field: props.field,
        value: Number(this.$refs.sizeInput.value),
        syntheticEvent: event}
      );
    },
    checkPath () {
      let path = '';
      if (this.path.length > 0) {
        path = this.computePathFromProp();
      }
      this.getPaths(path);
    },
    computePathFromProp () {
      let path = atob(this.path);
      path = path.split('/');
      path = path.slice(0, path.length - 1);
      for (let pathIndex = 0; pathIndex < path.length; pathIndex++) {
        let prePath = '';
        for (let index = 0; index < pathIndex; index++) {
          prePath = path[index]
        }
        path[pathIndex] = prePath + path[pathIndex] + '/';
      }
      this.parentList = path;
      return path[path.length - 1];
    },
    getPathsWrapperFunction (event, columnName, props) {
      this.getPaths(props.dataItem.path);
    },
    getPaths (path) {
      if (!path) {
        path = '';
      } else {
        if (path !== './' && !this.parentList.includes(path)) {
          this.parentList.push(path);
        } else {
          this.parentList.length = this.parentList.indexOf(path) + 1;
        }
      }

      if (path === './' || path === '') {
        path = '';
        this.parentList = [];
      }

      this.loading = true;
      this.axios.get('/CloudStorage/GetAll?path=' + path)
        .then( (response) => {
          if (response && response.data && response.data.directories) {
            this.directories = response.data.directories;
          }
          if (response && response.data && response.data.files) {
            this.files = response.data.files;
          }
          this.directories = this.removeParentsFromArray(this.directories);
          this.files = this.removeParentsFromArray(this.files);
          window.history.pushState({}, document.title, `/cloudstorage/${ btoa(this.parentList[this.parentList.length - 1]) }`)
          this.loading = false;
        })
        .finally(() => {
          let oldFilterIndex = this.kgm_filter.filters.indexOf(this.kgm_filter.filters.find(o => o.field == "name"));
          if (oldFilterIndex >= 0) {
            this.kgm_filter.filters.splice(oldFilterIndex, 1)
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    downloadWrapperFunction (event, columnName, props) {
      this.download(props.dataItem.path)
    },
    download (path) {
      // create a new and empty tab and open it (needs to be done beforehand because it will be blocked otherwise)
      this.axios.get('/CloudStorage/GetDownloadLink?path=' + path)
        .then((response) => {
          // set the location of the new tab (this will allow to send the information to the tab without it being blocked)
          window.location.assign(response.data.url);
        })
    },
    removeParentsFromArray (arrayToEdit) {
      if (typeof arrayToEdit != "object") {
        return arrayToEdit;
      }
      if (arrayToEdit.length === 0) {
        return arrayToEdit;
      }
      let arrayToReturn = [];
      arrayToEdit.forEach(item => {
        let tmpItem = {
          name: item.name,
          path: item.path,
          lastChangeDate: item.lastChangeDate,
          size: item.size
        };
        this.removeParents(item.path).replace("/", "");
        arrayToReturn.push(tmpItem);
      });
      return arrayToReturn;
    },
    removeParents (path) {
      let result = path;
      for (let i = this.parentList.length; i >= 0; i--) {
        if (result !== this.parentList[i]) {
          result = result.replace(this.parentList[i], '');
        }
        if (result === '/') {
          result = '.';
        }
      }
      return result;
    }
  }
}
</script>

<style scoped>
ul.m-menu__nav {
  line-height: 3rem;
}
ul.m-menu__nav li {
  list-style: none;
  display: block;
  float: left;
}
ul.m-menu__nav li a {
  padding: .5rem;
  background: rgb(231, 231, 231);
  -moz-border-radius: .25rem;
  -webkit-border-radius: .25rem;
  border-radius: .25rem;
  -khtml-border-radius: .25rem;
  border: 1px solid darkgray;
}
.m-menu__nav li.arrow {
  padding-left: 0;
  padding-right: 0;
}
.cloudStorageList .k-grid table tr:hover td {
  background :rgb(219, 219, 219) !important;
  cursor: pointer !important;
}
</style>