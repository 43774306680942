export default {
  methods: {
    numberConversionAdapter_bytes (bytes, si, decimals) {
      if (si == null || si == undefined) {
        si = true;
      }
      if (decimals == null || decimals == undefined) {
        decimals = 0;
      }
      
      if (Array.isArray(bytes)) {
        return this.numberConversionAdapter_bytesArray(bytes, si, decimals);
      }

      var thresh = si ? 1000 : 1024;
      var units = si
          ? ['B','kB','MB','GB','TB','PB','EB','ZB','YB']
          : ['B','KiB','MiB','GiB','TiB','PiB','EiB','ZiB','YiB'];
      var u = 0;
      while(Math.abs(bytes) >= thresh && u < units.length - 1)
      {
        bytes /= thresh;
        ++u;
      }
      return { bytes: bytes.toFixed(decimals), unit: units[u] };
    },
    numberConversionAdapter_bytesArray (bytesArray, si, decimals) {
      if (si == null || si == undefined) {
        si = true;
      }
      if (decimals == null || decimals == undefined) {
        decimals = 0;
      }

      let temporaryBytesArray = [];
      let highestNumber = Math.max.apply(Math, bytesArray);
      highestNumber = this.numberConversionAdapter_bytes(highestNumber, si);

      var thresh = si ? 1000 : 1024;
      var units = si
          ? ['B','kB','MB','GB','TB','PB','EB','ZB','YB']
          : ['B','KiB','MiB','GiB','TiB','PiB','EiB','ZiB','YiB'];

      bytesArray.forEach(bytes => {
        var u = 0;

        while(u < units.indexOf(highestNumber.unit)) { //Math.abs(bytes) >= thresh && 
            bytes /= thresh;
            ++u;
        }

        temporaryBytesArray.push({ bytes: bytes.toFixed(decimals), unit: highestNumber.unit });

      });
      return temporaryBytesArray;
    }
  }
}